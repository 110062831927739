$(document).ready(function() {

    //////////////////////////////////////////////////////////////////////////////
    // dropdown
    //

    const activators = document.querySelectorAll('.menu-activator')
    const dropdowns = document.querySelectorAll('.page-header-dropdown')
    let previousDropdown = null; // jquery
    let previousActivator = null; // jquery
    let mouseInDropdown = false;
    let mouseInActivator = false;
    let timer = 0

    for (const activator of activators) {
        const type = activator.getAttribute('data-target')
        activator.addEventListener('mouseenter', function(e) {
            if (e.target !== activator) {
                return
            }

            const dropdown = $(`.page-header-dropdown.${type}`)
            if (previousDropdown) {
                $(e.target).removeClass('active')
                previousDropdown.hide()
            }
            dropdown.show();
            previousDropdown = dropdown

            if (previousActivator) {
                previousActivator.removeClass('active')
            }
            previousActivator = $(e.target)
            previousActivator.addClass('active')

            mouseInActivator = true
        }, true);

        activator.addEventListener('mouseleave', function(e) {
            if (e.target !== activator) {
                return
            }

            if (timer) {
                clearTimeout(timer)
            }

            timer = setTimeout(function() {
                if (!mouseInDropdown && !mouseInActivator) {
                    if (previousActivator) {
                        previousActivator.removeClass('active')
                    }
                    previousDropdown.hide()
                }
            }, 1000);
        }, true)
    }

    for (const d of dropdowns) {
        d.addEventListener('mouseenter', function(e) {
            if (e.target !== d) {
                return
            }
            mouseInDropdown = true;
        }, true);
        d.addEventListener('mouseleave', function(e) {
            if (e.target !== d) {
                return
            }
            mouseInDropdown = false;
            
            $(`.page-header-dropdown`).hide();
            if (previousActivator) {
                previousActivator.removeClass('active')
            }
        }, true);
    }


    const triggers = $('.sub-menu-triger')
    for (const trigger of triggers) {
        const submenu = $(trigger).find('.sub-menu')
        trigger.addEventListener('mouseenter', function(e) {
            if (e.target !== trigger) {
                return
            }
            $(trigger).addClass('active')
            submenu.show()
        }, true)
        trigger.addEventListener('mouseleave', function(e) {
            if (e.target !== trigger) {
                return
            }
            $(trigger).removeClass('active')
            submenu.hide()
        }, true)
    }

    //////////////////////////////////////////////////////////////////////////////
    // highlight header menu
    //
    const navs = $('[data-match]').get().reverse()
    for (const nav of navs) {
        if (location.pathname.indexOf(nav.getAttribute('data-match')) >= 0) {
            nav.classList.add('hot');
            break;
        }
    }

    //////////////////////////////////////////////////////////////////////////////
    // video
    //
    $('#exampleModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var recipient = button.data('whatever') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this);
        modal.find('.embed-responsive-item').attr('src', recipient);
    });
    $('#exampleModal').on('hide.bs.modal', function (event) {
        var modal = $(this);
        modal.find('.embed-responsive-item').attr('src', "");
    });
})
