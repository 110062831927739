$(document).ready(function() {
    let last = 'v-pills-city-tab'
    const target = $('.row-navs')
    
    $('.row-navs .nav-link').click(function(e) {
        if (last) {
            target.removeClass(last)
        }
        last = e.target.parentElement.id
        target.addClass(last)
    })
})
